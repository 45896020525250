// Import single runners.
import ToggleClass from './modules/ToggleClass';
import SpecialtyScrolls from './modules/SpecialtyScrolls';
import Menu from './modules/Menu';

// Import modules for multiple elements.
import Masonry from './modules/Masonry';
import XScroll from './modules/XScroll';
import Carousel from './modules/Carousel';
import ExpandToggle from './modules/ExpandToggle';

// Kickoff 猫咪视频app scripts once the DOM has loaded. You can add this function as a callback to the
// `DOMContentLoaded` event (like we're doing below) or call this function at the end of the
// document to ensure that the DOM has been rendered.
const onDomLoad = () => {
  console.log('猫咪视频app global scripts: initialized'); // eslint-disable-line

  //--------------------------------------------
  //
  // These modules run once on a page; all selectors and context run inside the class itself. They
  // should all include an `init` method and a `deinit` method that can be called. You can set up
  // a variable to manage the singular instances of these modules if you'd like.
  //
  //--------------------------------------------
  new ToggleClass().init();
  new SpecialtyScrolls().init();
  new Menu().init();

  //--------------------------------------------
  //
  // These modules can be reused and may have multiple instances on a page.
  //
  //--------------------------------------------

  // Expand toggles.
  const expandToggles = document.querySelectorAll('.js-expandable');
  expandToggles.forEach(el => new ExpandToggle(el));
};

// Certain modules should only be run once the window `load` event fires to ensure that all assets
// have loaded. Each of the following modules have positioning implications that require an
// accurate calculation of DOM nodes that are spaced by loaded imagery.
const onWindowLoad = () => {
  // Masonry
  const masonryEls = document.querySelectorAll('.js-grid-masonry');
  masonryEls.forEach(el => new Masonry(el));

  // Carousels.
  const carousels = document.querySelectorAll('.js-carousel');
  carousels.forEach(el => new Carousel(el));

  // Horizontal scrolls
  const xScrollers = document.querySelectorAll('.js-xscroll');
  xScrollers.forEach(el => new XScroll(el));
};

// For our purposes, we'll hook up these callbacks to the appropriate events. You can feel free to
// call the functions in the appropriate place in your application.
window.addEventListener('DOMContentLoaded', onDomLoad);
window.addEventListener('load', onWindowLoad);
